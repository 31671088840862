@import "../node_modules/bootstrap/scss/bootstrap";

// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300;400&display=swap");

$font-family-base: "M PLUS Rounded 1c", sans-serif;
$body-bg: #fff;
$font-size-root: 40px;

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// 4. Include any optional Bootstrap components as you like
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";

// 5. Add additional custom code here
// Default variable overrides

body {
  font-family: "M PLUS Rounded 1c", sans-serif !important;
}

h2 {
  line-height: var(--bs-body-line-height);
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0px;
}

#cleanup {
  font-size: 1.2em;
  font-weight: normal;
}

#clock {
  #date {
    font-size: 2.5em;
    font-weight: bold;
    line-height: 1em;
  }
  #time {
    font-size: 2.5em;
    font-weight: bold;
    line-height: 1em;
  }
}

#train-timetable {
  font-size: 1em;
  .for {
    font-weight: bold;
  }
}

#cleanup {
  font-size: 1em;
}

#weather {
  .date {
    font-size: 1em;
    font-weight: bold;
  }

  img {
    width: 150px;
  }

  .high {
    font-size: 0.7em;
    color: rgb(253, 35, 96);
    line-height: 1.1em;
    display: block;
  }

  .low {
    font-size: 0.7em;
    color: rgb(0, 115, 255);
    line-height: 1.1em;
    display: block;
  }

  vertical-align: middle;
}

#slack {
  h3 {
    font-size: 1em;
    font-weight: 700;
  }
  #message {
    font-size: 1em;
  }
}

.deadlinedays {
  color: #ff3381;
  font-weight: bold;
}

/* 点滅 */
.blinking {
  -webkit-animation: blink 1.5s ease-in-out infinite alternate;
  -moz-animation: blink 1.5s ease-in-out infinite alternate;
  animation: blink 1.5s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
